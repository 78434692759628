import { useSignInMutation, useSignUpMutation } from "@/__generated__/graphql";
import env from "@/env";
import { extractGraphQLErrorMessage } from "@/helpers";
import { SignInMode } from "@/modules/app";
import { useAppContext } from "@/modules/app/providers/AppContextProvider";
import SignInView from "@/modules/auth/views/SignInView";
import { useState } from "react";
import { getUTMSourceFromLocalStorage } from "./utmUtils";

export default function SignIn({
  phone,
  newUser,
  onNext,
  onBack,
  signInMode,
}: {
  phone: string;
  newUser:
    | false
    | {
        name: string;
        surname: string;
      };
  onNext: () => void;
  onBack: () => void;
  signInMode: SignInMode;
}) {
  const [code, setCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [wasAutoSubmitted, setWasAutoSubmitted] = useState(false);
  const { setAuthToken } = useAppContext();
  const parsedPhone = `+${phone.replace(/\D/g, "")}`;

  const [signIn] = useSignInMutation();
  const [signUp] = useSignUpMutation();

  const utmSource = getUTMSourceFromLocalStorage() ?? "";

  const onSubmit = async (code: string) => {
    setLoading(true);

    try {
      const { data } = await (newUser
        ? signUp({
            variables: {
              phone: parsedPhone,
              name: newUser.name,
              surname: newUser.surname,
              utmSource: utmSource,
              locationId: env.REACT_APP_LOCATION_ID,
              locationRecId: env.REACT_APP_LOCATION_REC_ID,
              code,
            },
          })
        : signIn({
            variables: {
              phone: parsedPhone,
              locationId: env.REACT_APP_LOCATION_ID,
              code,
            },
          }));

      data && setAuthToken(data.auth.token);
      onNext();
    } catch (error) {
      setError(extractGraphQLErrorMessage(error));
      setLoading(false);
    }
  };

  const onChangeCode = (value: string) => {
    setError(null);
    setCode(value);

    if (value.length === 6 && !wasAutoSubmitted) {
      onSubmit(value);
      setWasAutoSubmitted(true);
    }
  };

  return (
    <SignInView
      code={code}
      phone={phone}
      onChangeCode={onChangeCode}
      onSubmit={() => onSubmit(code)}
      loading={loading}
      error={error}
      onBack={onBack}
      signInMode={signInMode}
    />
  );
}
