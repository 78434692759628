import { PassStatus, UserProfile } from "@/__generated__/graphql";
import { LessonsListView } from "@/components/lists/LessonsListView/LessonsListView";
import { PassesListView } from "@/components/lists/PassesListView/PassesListView";
import { Phone } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/joy";
import { FormattedMessage } from "react-intl";
import { Link, Link as RouterLink } from "react-router-dom";

export default function HomeView({
  user,
  lessons,
  passes,
}: {
  user: UserProfile;
  lessons: LinkedLesson[];
  passes: PartialPass[];
}) {
  return (
    <Stack gap={4} p={2} pt={4} pb={12}>
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Typography level="h3">
          <FormattedMessage
            defaultMessage={`Здравствуйте, {name} 💕`}
            values={{ name: user.name }}
          />
        </Typography>
      </Stack>

      <Stack gap={2}>
        <Typography level="title-lg">
          <FormattedMessage defaultMessage="Ближайшие занятия" />
        </Typography>
        {lessons.length === 0 ? (
          <>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography color="neutral">
                <FormattedMessage defaultMessage="Нет записей" />
              </Typography>
            </Box>

            <Button variant="outlined" component={RouterLink} to="/">
              <FormattedMessage defaultMessage="Записаться" />
            </Button>
          </>
        ) : (
          <LessonsListView lessons={lessons} />
        )}
      </Stack>

      <Stack gap={2}>
        <Typography level="title-lg">
          <FormattedMessage defaultMessage="Ваши абонементы" />
        </Typography>
        <PassesListView passes={passes} />

        {passes.filter(
          (pass) =>
            ![
              PassStatus.Expired,
              PassStatus.NoHours,
              PassStatus.Refunded,
            ].includes(pass.status)
        ).length === 0 && (
          <Alert variant="outlined" size="sm">
            <Typography level="body-sm">
              <FormattedMessage defaultMessage="Для оформления абонемента пожалуйста обратитесь к администратору" />
            </Typography>
          </Alert>
        )}
      </Stack>

      {user.administrator &&
        user.administrator.appName &&
        user.administrator.avatar &&
        user.administrator.phone &&
        user.administrator.telegram && (
          <Stack gap={2}>
            <Typography level="title-lg">
              <FormattedMessage defaultMessage="Ваш личный администратор" />
            </Typography>
            <Link
              to={`tel://${user.administrator.phone}`}
              target="blank"
              style={{ textDecoration: "none" }}
            >
              <Stack direction="row">
                <Avatar
                  size="lg"
                  sx={{ mr: 2 }}
                  src={
                    user.administrator.avatar.thumbnail ||
                    user.administrator.avatar.url
                  }
                />
                <Stack
                  flex={1}
                  direction="row"
                  sx={{ justifyContent: "space-between" }}
                >
                  <Box>
                    <Typography level="title-md">
                      <b>{user.administrator.appName}</b>
                    </Typography>
                    <Typography level="body-sm" textColor="neutral.400">
                      {user.administrator.phone}
                    </Typography>
                  </Box>

                  <IconButton sx={{ px: 2 }} variant="soft">
                    <Phone />
                  </IconButton>
                </Stack>
              </Stack>
            </Link>

            <Button
              variant="outlined"
              component={Link}
              to={`https://t.me/${user.administrator.telegram}`}
              target="blank"
            >
              <FormattedMessage defaultMessage="Связаться в Telegram" />
            </Button>
          </Stack>
        )}
    </Stack>
  );
}
