export function getURLParameter(name: string): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}

export function saveUTMSourceToLocalStorage() {
  const utmSource = getURLParameter("utmSource");
  if (utmSource) {
    localStorage.setItem("utmSource", utmSource);
  }
}

export function getUTMSourceFromLocalStorage(): string | null {
  return localStorage.getItem("utmSource");
}
